import React from 'react';
import { graphql, withPrefix, Link } from 'gatsby';
import Helmet from 'react-helmet';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import Call from '../components/Call';
import HeroArea from '../components/HeroArea';
import Bryant from '../images/hvac-service-repair.png';

import Equip from '../images/FinancingOptions_LearnMore_728x90_B.png';
const Rebate = (props) => {
  return (
    <Layout bodyClass="page-financing">
      <SEO
        title="Register Your HVAC Equipment"
        description="Minneseota HVAC Registration"
      />

      <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24">
        <div className="text-center">
          <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
            <span className="block xl:inline">Register Your</span>{' '}
            <span className="block text-indigo-600 xl:inline">Equipment</span>
          </h1>
          <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            Questions about registering your equipment? Contact us here.
          </p>
          <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
            <div className="rounded-md shadow">
              <Link
                to="http://localhost:8000/hvac-service-installation-repair-scheduling"
                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 md:py-4 md:text-lg md:px-10"
              >
                Email
              </Link>
            </div>
            <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
              <a
                href="tel:952-435-3633"
                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-blue-600 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10"
              >
                Phone
              </a>
            </div>
          </div>
        </div>
      </main>

      <div className="prose max-w-prose mx-auto">
        <h2>Registration:</h2>
        <div>
          <p>
            The terms and coverage details of your warranty are specific to the
            Bryant product models you own. Typically, Bryant products, if
            registered within 90 days, come with a 10-year standard parts
            limited warranty: otherwise 5 years*. Depending on your model,
            certain components within the system may have different standard
            warranty periods. Even with the standard parts warranty, repair
            labor bills can range from $100 to $1000 or more. To learn the exact
            details of your warranty, refer to the product documentation that
            came with your product. If your model is listed on the Bryant
            website, the documentation may be available for download. Or, if you
            are unable to find your documentation, you can always contact
            LaSalle Heating and Air Conditioning, Inc. for assistance in
            determining your warranty coverage. Please note, the additional
            labor warranty coverage provided by LaSalle Heating and Air
            Conditioning, Inc., at the time of purchase, is not underwritten by
            Bryant Heating & Cooling.
          </p>

          <p>
            *LaSalle Heating and Air Conditioning, Inc. includes a standard
            5-year labor warranty on all qualifying equipment purchased and
            installed by LaSalle Heating and Air Conditioning, Inc. at the
            address indicated on the original invoice. When successfully
            registered at www.bryant.com within 90 days of purchase, LaSalle
            Heating and Air Conditioning, Inc extends our standard labor
            warranty for an additional 5 years to the original purchasing owner.
            Subsequent owners of equipment installed at the address indicated on
            the original invoice will receive only the remainder of
            above-mentioned standard labor warranty starting on the date of
            installation as shown on the original invoice.
          </p>
          <img src={Bryant} alt="minnesota-home-hvac-company" />
        </div>
        <div className="mt-3  sm:mt-0 sm:ml-3 py-20">
          <a
            href="https://productregistration.bryant.com/public/RegistrationForm?brand=BRYANT"
            className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 md:py-4 md:text-lg md:px-10"
          >
            Register Here
          </a>
        </div>
      </div>
    </Layout>
  );
};

export default Rebate;
